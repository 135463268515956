@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Global scroll behavior for hash links */
html {
  scroll-behavior: smooth;
}

/* Landing page variables */
:root {
  --landing-primary-dark-blue: #0f172a;
  --landing-primary-blue: #1e3a8a;
  --landing-secondary-blue: #3b82f6;
  --landing-accent-purple: #8b5cf6;
  --landing-accent-teal: #0ea5e9;
  --landing-text-light: #f8fafc;
  --landing-text-gray: #94a3b8;
  --landing-card-bg: rgba(30, 41, 59, 0.7);
  --landing-card-border: rgba(148, 163, 184, 0.1);
}

/* Reset styles specifically for landing page elements */
.landing-page * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main landing page container */
.landing-page {
  min-height: 100vh;
  background: linear-gradient(135deg, var(--landing-primary-dark-blue), var(--landing-primary-blue));
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  color: var(--landing-text-light);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* Navigation Bar */
.landing-page .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  z-index: 100;
  backdrop-filter: blur(10px);
  background: rgba(15, 23, 42, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.landing-page .navbar.scrolled {
  padding: 1rem 2rem;
  background: rgba(15, 23, 42, 0.95);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.landing-page .navbar-content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page .landing-logo {
  font-weight: 700;
  font-size: 1.5rem;
  background: linear-gradient(90deg, var(--landing-text-light), var(--landing-accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 101;
}

.landing-page .landing-logo a {
  text-decoration: none;
}

.landing-page .nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.landing-page .nav-links a {
  color: var(--landing-text-gray);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.landing-page .nav-links a:hover {
  color: var(--landing-text-light);
}

.landing-page .login-btn, 
.landing-page .signup-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  text-decoration: none;
  display: inline-block;
}

.landing-page .login-btn {
  color: var(--landing-text-light);
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-page .login-btn:hover {
  background: rgba(255, 255, 255, 0.05);
}

.landing-page .signup-btn {
  background: linear-gradient(90deg, var(--landing-accent-teal), var(--landing-accent-purple));
  color: var(--landing-text-light);
  border: none;
}

.landing-page .signup-btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Mobile Menu */
.landing-page .menu-toggle {
  display: none;
  background: transparent;
  border: none;
  color: var(--landing-text-light);
  cursor: pointer;
  z-index: 101;
}

.landing-page .mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--landing-primary-dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.landing-page .mobile-menu-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  padding: 2rem;
}

.landing-page .mobile-menu a {
  color: var(--landing-text-light);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.landing-page .mobile-menu a:hover {
  color: var(--landing-accent-purple);
}

.landing-page .mobile-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.landing-page .mobile-buttons .login-btn,
.landing-page .mobile-buttons .signup-btn {
  width: 100%;
  text-align: center;
  padding: 1rem;
}

/* Hero Section */
.landing-page .hero-section {
  display: flex;
  padding: 10rem 2rem 6rem;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.landing-page .hero-content {
  flex: 1;
  max-width: 640px;
  z-index: 1;
}

.landing-page .hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, var(--landing-text-light), var(--landing-accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page .hero-subtitle {
  font-size: 1.25rem;
  color: var(--landing-text-gray);
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.landing-page .hero-cta {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.landing-page .primary-button {
  background: linear-gradient(90deg, var(--landing-accent-teal), var(--landing-accent-purple));
  color: var(--landing-text-light);
  padding: 0.875rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  font-size: 1rem;
  box-shadow: 0 10px 25px rgba(8, 112, 184, 0.3);
}

.landing-page .primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(8, 112, 184, 0.4);
}

.landing-page .text-link {
  color: var(--landing-text-gray);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.landing-page .text-link:hover {
  color: var(--landing-text-light);
}

.landing-page .hero-graphic {
  position: relative;
  width: 500px;
  height: 500px;
}

.landing-page .gradient-sphere {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.3), rgba(139, 92, 246, 0.1));
  border-radius: 50%;
  filter: blur(50px);
  animation: pulse 8s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

/* Features Section */
.landing-page .features-section {
  padding: 6rem 2rem;
  max-width: 1280px;
  margin: 0 auto;
}

.landing-page .section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 4rem;
  text-align: center;
  background: linear-gradient(90deg, var(--landing-text-light), var(--landing-accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page .features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.landing-page .feature-card {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.landing-page .feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.landing-page .feature-icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--landing-accent-teal), var(--landing-accent-purple));
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.landing-page .feature-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.landing-page .feature-card p {
  color: var(--landing-text-gray);
  line-height: 1.6;
}

.landing-page .feature-cta {
  margin-top: auto;
  color: var(--landing-secondary-blue);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  width: fit-content;
}

.landing-page .feature-cta:hover {
  color: var(--landing-accent-purple);
}

.landing-page .feature-cta::after {
  content: "→";
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
}

.landing-page .feature-cta:hover::after {
  transform: translateX(3px);
}

.landing-page .secondary-button {
  background: var(--landing-accent-teal);
  color: var(--landing-text-light);
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  font-weight: 500;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  display: inline-block;
  margin-top: auto;
}

.landing-page .secondary-button:hover {
  background: var(--landing-secondary-blue);
  transform: translateY(-2px);
}

/* Partner Section */
.landing-page .partner-section {
  padding: 4rem 2rem;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(10px);
}

.landing-page .partner-section h2 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--landing-text-gray);
}

.landing-page .logo-slider {
  overflow: hidden;
}

.landing-page .logo-slide-track {
  display: flex;
  animation: scroll 30s linear infinite;
  width: calc(250px * 10);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5));
  }
}

.landing-page .logo-slide {
  flex: 0 0 250px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page .firm-logo {
  height: 45px;
  width: auto;
  object-fit: contain;
  opacity: 0.4;
  transition: opacity 0.3s ease, transform 0.3s ease;
  filter: grayscale(100%) brightness(2);
}

.landing-page .firm-logo:hover {
  opacity: 1;
  transform: scale(1.1);
  filter: grayscale(0%);
}

/* FAQ Section */
.landing-page .faq-section {
  padding: 6rem 2rem;
  max-width: 900px;
  margin: 0 auto;
}

.landing-page .faq-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.landing-page .faq-item {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.landing-page .faq-item:hover {
  transform: translateY(-5px);
}

.landing-page .faq-item h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--landing-text-light);
}

.landing-page .faq-item p {
  color: var(--landing-text-gray);
  line-height: 1.6;
}

/* CTA Section */
.landing-page .cta-section {
  padding: 6rem 2rem;
  text-align: center;
  background: linear-gradient(135deg, rgba(14, 165, 233, 0.15), rgba(139, 92, 246, 0.15));
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.landing-page .cta-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  max-width: 700px;
}

.landing-page .cta-section p {
  font-size: 1.25rem;
  color: var(--landing-text-gray);
  max-width: 600px;
  margin: 0 auto;
}

/* Footer */
.landing-page .footer {
  background: rgba(15, 23, 42, 0.9);
  backdrop-filter: blur(10px);
  padding: 4rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.landing-page .footer-content {
  max-width: 1280px;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page .footer-logo {
  font-weight: 700;
  font-size: 1.5rem;
  background: linear-gradient(90deg, var(--landing-text-light), var(--landing-accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page .footer-links {
  display: flex;
  gap: 2rem;
}

.landing-page .footer-links a, 
.landing-page .social-links a {
  color: var(--landing-text-gray);
  text-decoration: none;
  transition: color 0.2s ease;
}

.landing-page .footer-links a:hover, 
.landing-page .social-links a:hover {
  color: var(--landing-text-light);
}

.landing-page .copyright {
  text-align: center;
  color: var(--landing-text-gray);
  font-size: 0.875rem;
  margin-top: 2rem;
}

/* Background decorations */
.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect fill="%23ffffff" fill-opacity="0.03" width="1" height="1"/></svg>');
  pointer-events: none;
  z-index: 0;
}

/* Subpage styles */
.landing-page .subpage-container {
  padding: 8rem 2rem 4rem;
  max-width: 1100px;
  margin: 0 auto;
}

.landing-page .subpage-header {
  text-align: center;
  margin-bottom: 4rem;
}

.landing-page .subpage-header h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, var(--landing-text-light), var(--landing-accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-page .subpage-header p {
  color: var(--landing-text-gray);
  font-size: 1.25rem;
}

/* Features page */
.landing-page .feature-section {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 4rem;
}

.landing-page .feature-block {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  border-radius: 1rem;
  padding: 2.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.landing-page .feature-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.landing-page .feature-icon-large {
  width: 64px;
  height: 64px;
  background: linear-gradient(135deg, var(--landing-accent-teal), var(--landing-accent-purple));
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 1.5rem;
}

.landing-page .feature-block h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--landing-text-light);
}

.landing-page .feature-block p {
  color: var(--landing-text-gray);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.landing-page .feature-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.landing-page .feature-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: var(--landing-text-gray);
}

.landing-page .feature-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.6rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--landing-accent-teal), var(--landing-accent-purple));
}

.landing-page .cta-container {
  text-align: center;
  margin-top: 4rem;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, rgba(14, 165, 233, 0.1), rgba(139, 92, 246, 0.1));
  border-radius: 1rem;
}

.landing-page .cta-container h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.landing-page .cta-container p {
  color: var(--landing-text-gray);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Contact page */
.landing-page .contact-section {
  margin-bottom: 4rem;
}

.landing-page .contact-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 3rem;
  align-items: start;
}

.landing-page .contact-details {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  border-radius: 1rem;
  padding: 2.5rem;
}

.landing-page .contact-details h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.landing-page .contact-method {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.landing-page .contact-icon {
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, var(--landing-accent-teal), var(--landing-accent-purple));
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 1rem;
}

.landing-page .contact-info h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.landing-page .contact-info p {
  color: var(--landing-text-gray);
}

.landing-page .social-contact {
  margin-top: 3rem;
}

.landing-page .social-contact h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.landing-page .social-icons {
  display: flex;
  gap: 1rem;
}

.landing-page .social-icons a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--landing-text-gray);
  transition: all 0.2s ease;
}

.landing-page .social-icons a:hover {
  transform: translateY(-3px);
  background: linear-gradient(135deg, var(--landing-accent-teal), var(--landing-accent-purple));
  color: white;
}

.landing-page .contact-form-container {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  border-radius: 1rem;
  padding: 2.5rem;
}

.landing-page .contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.landing-page .contact-form h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.landing-page .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.landing-page .form-group label {
  font-weight: 500;
  color: var(--landing-text-light);
}

.landing-page .form-group input,
.landing-page .form-group textarea {
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--landing-card-border);
  background: rgba(255, 255, 255, 0.05);
  color: var(--landing-text-light);
  font-family: 'Inter', sans-serif;
  resize: vertical;
}

.landing-page .form-group input:focus,
.landing-page .form-group textarea:focus {
  outline: none;
  border-color: var(--landing-accent-teal);
  box-shadow: 0 0 0 2px rgba(14, 165, 233, 0.2);
}

.landing-page .error-message {
  color: #ef4444;
  margin-bottom: 1rem;
}

.landing-page .success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.landing-page .success-message svg {
  color: #10b981;
  margin-bottom: 1.5rem;
}

.landing-page .success-message h3 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.landing-page .success-message p {
  color: var(--landing-text-gray);
  margin-bottom: 2rem;
}

/* FAQ page */
.landing-page .faq-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
}

.landing-page .faq-accordion-item {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-page .faq-accordion-item.open {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.landing-page .faq-question {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-page .faq-question h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.landing-page .faq-answer {
  padding: 0 1.5rem 1.5rem;
  overflow: hidden;
}

.landing-page .faq-answer p {
  color: var(--landing-text-gray);
  line-height: 1.6;
  margin: 0;
}

.landing-page .faq-cta {
  text-align: center;
  margin-top: 4rem;
  padding: 3rem 2rem;
  background: linear-gradient(135deg, rgba(14, 165, 233, 0.1), rgba(139, 92, 246, 0.1));
  border-radius: 1rem;
}

.landing-page .faq-cta h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.landing-page .faq-cta p {
  color: var(--landing-text-gray);
  margin-bottom: 1.5rem;
}

/* Policy pages (Privacy, Terms) */
.landing-page .policy-content {
  background: var(--landing-card-bg);
  border: 1px solid var(--landing-card-border);
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 4rem;
}

.landing-page .policy-section {
  margin-bottom: 3rem;
}

.landing-page .policy-section:last-child {
  margin-bottom: 0;
}

.landing-page .policy-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: var(--landing-text-light);
}

.landing-page .policy-section p {
  color: var(--landing-text-gray);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.landing-page .policy-section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--landing-text-gray);
}

.landing-page .policy-section ul li {
  margin-bottom: 0.5rem;
}

/* Responsive adjustments for subpages */
@media (max-width: 1024px) {
  .landing-page .hero-section {
    flex-direction: column;
    padding-top: 8rem;
    text-align: center;
  }

  .landing-page .hero-content {
    max-width: 100%;
  }

  .landing-page .hero-title {
    font-size: 2.5rem;
  }

  .landing-page .hero-cta {
    justify-content: center;
  }

  .landing-page .hero-graphic {
    margin-top: 4rem;
    width: 300px;
    height: 300px;
  }
  
  .landing-page .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .landing-page .navbar {
    padding: 1rem;
  }

  .landing-page .desktop-nav {
    display: none;
  }
  
  .landing-page .menu-toggle {
    display: block;
  }
  
  .landing-page .features-grid {
    grid-template-columns: 1fr;
  }

  .landing-page .feature-card {
    padding: 1.5rem;
  }
  
  .landing-page .section-title {
    font-size: 2rem;
  }
  
  .landing-page .hero-title {
    font-size: 2rem;
  }
  
  .landing-page .hero-subtitle {
    font-size: 1rem;
  }

  .landing-page .footer-content {
    flex-direction: column;
    gap: 2rem;
  }

  .landing-page .footer-links {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  
  .landing-page .cta-section h2 {
    font-size: 1.75rem;
  }
  
  .landing-page .cta-section p {
    font-size: 1rem;
  }
  
  .landing-page .contact-content {
    grid-template-columns: 1fr;
  }
  
  .landing-page .contact-method {
    flex-direction: column;
  }
  
  .landing-page .contact-icon {
    margin-bottom: 1rem;
    margin-right: 0;
  }
  
  .landing-page .subpage-header h1 {
    font-size: 2rem;
  }
  
  .landing-page .subpage-header p {
    font-size: 1rem;
  }
  
  .landing-page .feature-block,
  .landing-page .contact-details,
  .landing-page .contact-form-container,
  .landing-page .policy-content {
    padding: 1.5rem;
  }
}